import React from "react";
import "../../assets/EventGallery.css";

const EventGallery = ({ events }) => {
    return (
        <div className="event-gallery">
            {events.map((event, index) => (
                <div key={index} className="event-section">
                    <h2>{event.name}</h2>
                    <div className="gallery-grid">
                        {event.images.map((image, idx) => (
                            <div key={idx} className="gallery-item">
                                <img src={image} alt={`Event ${event.name} ${idx + 1}`} />
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default EventGallery;
