import React from "react";
import "../../assets/AboutUs.css";

const AboutUs = () => {
  return (
    <div className="about-us">
      <div className="about-content">
        <h2 className="about-title">Despre Noi</h2>
        <div className="about-line"></div>
        <p className="about-intro">
          Premium Park este un complex modern, situat departe de zona aglomerată
          a orașului, destinat organizării de evenimente. Acesta dispune de două
          săli și poate găzdui evenimente de la 50 până la 160 de persoane la
          banchete și până la 260 de persoane la evenimente de tip fourchette.
        </p>
        <p className="about-description"></p>
        <p className="about-mission">
          Cu o experiență de 18 ani în domeniu și peste 3000 de evenimente
          organizate, la Premium Park, atenția la detalii este primordială,
          fiecare aspect al sălilor și serviciilor fiind conceput pentru a
          asigura o experiență de neuitat. Oferta gastronomică include un meniu
          divers și sofisticat. De asemenea, echipa oferă ajutor și asistență în
          organizarea evenimentelor, inclusiv decor, echipamente audio-vizuale
          și servicii de catering de înaltă calitate.
        </p>
      </div>
      <div className="about-image">
        <img
          src="https://premiumclient-huqx.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fdespre3.b1ca8c04.jpg&w=384&q=75"
          alt="Despre Noi"
        />
      </div>
    </div>
  );
};

export default AboutUs;
