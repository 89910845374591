import React, { useState } from "react";
import "../../assets/Navbar.css";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <Link to="/">PREMIUM PARK</Link>
      </div>
      <div
        className={`menu-toggle ${isOpen ? "is-active" : ""}`}
        onClick={toggleMenu}
      >
        <span className="burger-line"></span>
        <span className="burger-line"></span>
        <span className="burger-line"></span>
      </div>
      <ul className={`navbar-links ${isOpen ? "active" : ""}`}>
        <li>
          <Link to="/">Acasă</Link>
        </li>
        <li>
          <Link to="salile-noastre">Salile</Link>
        </li>
        <li>
          <Link to="servicii">Servicii</Link>
        </li>
        <li>
          <Link to="evenimente">Evenimente</Link>
        </li>
        <li>
          <Link to="contact">Contacte</Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
