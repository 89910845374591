import React, { useEffect } from "react";
import "../../../assets/SticlaDescription.css";
import AOS from "aos";
import "aos/dist/aos.css";

const SticlaDescription = () => {
  const servicesData = [
    {
      title: "Terasa De Sticla",
      images: [
        "https://i.imgur.com/cnKo2Za.jpeg",
        "https://i.imgur.com/plDQuWe.jpeg",
      ],
      text: [
        {
          subtitle: "Capacitate:",
          description:
            "Sala Grandis poate găzdui un număr intre 150 - 500 de invitați, oferind suficient spațiu pentru organizarea de nunți, botezuri sau alte evenimente festive. Aceasta este adaptabilă pentru diverse tipuri de aranjamente, având o capacitate variabilă, în funcție de specificul evenimentului.",
        },
        {
          subtitle: "Design Interior:",
          description:
            "Interior modern, cu finisaje de înaltă calitate, candelabre elegante și detalii ce reflectă rafinamentul. Atmosfera este una sofisticată, perfectă pentru un eveniment deosebit. Luminile ambientale și decorul personalizabil sunt câteva dintre caracteristicile care conferă unicitate acestui spațiu.",
        },
        {
          subtitle: "Locație:",
          description:
            "Situată într-o zonă liniștită, dar accesibilă, sala este înconjurată de un peisaj verde, care adaugă un plus de frumusețe și serenitate evenimentului.",
        },
      ],
    },
  ];
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Inițializează AOS cu durata animațiilor
  }, []);

  return (
    <div className="services-container-page">
      <div className="services-list-page">
        {servicesData.map((service, index) => (
          <div
            data-aos="zoom-in-down"
            data-aos-once="true"
            className="service-item-page"
            key={index}
          >
            <div className="service-image-gallery-page">
              {service.images.map((image, idx) => (
                <img
                  key={idx}
                  src={image}
                  alt={`${service.title} ${idx + 1}`}
                  className="service-image-page"
                  loading="lazy"
                />
              ))}
            </div>
            <div className="service-info-page">
              <h1>{service.title}</h1>
              {service.text.map((item, idx) => (
                <div className="service-text-page" key={idx}>
                  <h3 className="service-subtitle-page">{item.subtitle}</h3>
                  <p className="service-description-page">{item.description}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SticlaDescription;
