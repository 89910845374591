import React from "react";
import ContactUs from "../components/Contact/ContactUs";
import Maps from "../components/Contact/Maps";


const ContactPage = () => {
    return (
        <div>
            <ContactUs />
            <Maps />
        </div>
    );
};

export default ContactPage;
