import React, { useEffect } from "react";
import "../../assets/ServiceCard.css";
import AOS from "aos";
import "aos/dist/aos.css";

const ServiceCard = () => {
  const servicesData = [
    {
      title: "Fruit Bar",
      images: [
        "https://i.imgur.com/WerkINY.jpeg",
        "https://i.imgur.com/ajO4Ky8.jpeg",
      ],
      text: [
        {
          subtitle: "Bar cu fructe proaspete:",
          description:
            "Fiecare eveniment beneficiază de un bar cu fructe proaspete, unde o selecție variată de fructe sunt disponibile pentru a adăuga un plus de energie și prospețime participanților.",
        },
        {
          subtitle: "Băuturi sănătoase și răcoritoare:",
          description:
            "Oferim o gamă largă de sucuri naturale, smoothie-uri și băuturi răcoritoare din fructe, pregătite pe loc, care vor încânta simțurile și vor susține o atmosferă vibrantă și revigorantă pe parcursul evenimentului.",
        },
        {
          subtitle: "Personal calificat:",
          description:
            "La Fruit Bar, veți fi întâmpinați de un personal specializat, care va pregăti și servi băuturi personalizate, adaptate preferințelor fiecărui participant, oferind o experiență unică și atentă la detalii.",
        },
        {
          subtitle: "Servicii complete de amenajare:",
          description:
            "Oferim servicii de amenajare și personalizare a Fruit Bar-ului, asigurându-ne că totul este pregătit conform tematicii evenimentului, astfel încât atmosfera să fie perfectă și armonioasă.",
        },
      ],
    },
    {
      title: "Fourchette sărat",
      images: [
        "https://i.imgur.com/kCe0jai.jpeg",
        "https://i.imgur.com/XJl09CA.jpeg",
      ],
      text: [
        {
          subtitle: "Producție proprie:",
          description:
            "Fiecare gustare este elaborată de bucătarul Max Budeci. Astfel, asigurăm originalitatea și exclusivitatea fouchette-ului, oferind o experiență culinară de neuitat.",
        },
        {
          subtitle: "Perfect pentru întâmpinarea oaspeților:",
          description:
            "Fourchette-ul sărat este ideal pentru întâmpinarea oaspeților, oferindu-le ocazia de a socializa și de a se pregăti pentru experiența culinară ce urmează.",
        },
        {
          subtitle: "Personalizare:",
          description:
            "Pentru a face fiecare eveniment irepetabil, gustările sunt alese individual. Astfel, personalizăm meniul pentru a asigura că fiecare detaliu reflectă gusturile și dorințele oaspeților.",
        },
        {
          subtitle: "Evenimente fourchette:",
          description:
            "Organizăm evenimente de tip fourchette, unde toate gustările sunt servite sub formă de finger foods. Acest format permite oaspeților să savureze o varietate de combinații gustative într-un mod convenabil și plăcut.",
        },
      ],
    },
    {
      title: "Turn din pahare de Prosecco",
      images: [
        "https://i.imgur.com/fBEkYyC.jpeg",
        "https://i.imgur.com/fBEkYyC.jpeg",
      ],
      text: [
        {
          subtitle: "Turn spectaculos din pahare de Prosecco:",
          description:
            "Pentru un moment memorabil, vă oferim un turn impresionant din pahare de Prosecco, creat pentru a adăuga un plus de eleganță și spectaculozitate evenimentului dumneavoastră.",
        },
        {
          subtitle: "Servire exclusivă de Prosecco:",
          description:
            "Prosecco-ul din turn este servit cu grijă și rafinament, oferind un gust delicat și festiv. Acesta poate fi personalizat cu arome sau garnituri speciale, pentru a satisface preferințele invitaților și pentru a crea o atmosferă deosebită.",
        },
        {
          subtitle: "Personal dedicat:",
          description:
            "Un personal specializat se va ocupa de montarea, servirea și întreținerea turnului, asigurându-se că fiecare detaliu este perfect. Servirea se va face cu eleganță, iar invitații vor fi impresionați de acest moment deosebit.",
        },
        {
          subtitle: "Design personalizat:",
          description:
            "Turnul de pahare de Prosecco poate fi adaptat în funcție de tematica evenimentului dumneavoastră, fiind un element central care va atrage toate privirile și va adăuga o notă de lux și sofisticare.",
        },
      ],
    },
    {
      title: "Tortul Mirelui",
      images: [
        "https://i.imgur.com/VMxh97n.jpeg",
        "https://i.imgur.com/VMxh97n.jpeg",
      ],
      text: [
        {
          subtitle: "Design unic și elegant:",
          description:
            "Fiecare tort este realizat cu mare atenție la detalii, având un design elegant, cu decorațiuni din flori comestibile, cristale de zahăr sau alte elemente fine.",
        },
        {
          subtitle: "Gusturi și arome personalizate:",
          description:
            "Tortul Mirelui poate fi personalizat cu diverse arome și umpluturi, pentru a satisface toate gusturile. De la vanilie și ciocolată, până la fructe sau combinații sofisticate de arome, fiecare mușcătură va fi o adevărată delicatesă.",
        },
        {
          subtitle: "Servire elegantă:",
          description:
            "Tortul va fi servit cu eleganță și profesionalism, iar momentul tăierii tortului va deveni un moment deosebit al evenimentului, însoțit de aplauze și zâmbete, creând o atmosferă festivă și memorabilă.",
        },
        {
          subtitle: "Asistență completă:",
          description:
            "Oferim asistență completă în alegerea și personalizarea tortului, pentru a ne asigura că acesta se potrivit perfect cu viziunea și dorințele dumneavoastră.",
        },
      ],
    },
    {
      title: "Catering",
      images: [
        "https://i.imgur.com/8jUYTCV.jpeg",
        "https://i.imgur.com/kSojGEm.jpeg",
      ],
      text: [
        {
          subtitle: "Preparate fine și gustoase:",
          description:
            "Fiecare fel de mâncare este pregătit cu atenție și pasiune, asigurându-se că fiecare detaliu contează. De la aperitive elegante, feluri principale rafinate, până la deserturi spectaculoase.",
        },
        {
          subtitle: "Servire impecabilă:",
          description:
            "Echipa noastră de ospătari experimentați va asigura o servire impecabilă, atentă și profesională, contribuind la crearea unei atmosfere plăcute și relaxante pe tot parcursul evenimentului. Fiecare invitat va beneficia de o experiență culinară de top.",
        },
        {
          subtitle: "Opțiuni vegetariene:",
          description:
            "Înțelegem importanța diversității și incluziunii, de aceea oferim opțiuni vegetariene, vegane și fără gluten, pentru a ne asigura că toți invitații se vor bucura de mâncăruri delicioase și adaptate nevoilor lor.",
        },
        {
          subtitle: "Prezentare elegantă:",
          description:
            "Fiecare preparat va fi prezentat într-un mod elegant și atrăgător, transformând mesele într-o adevărată sărbătoare vizuală, perfectă pentru a însoți atmosfera evenimentului și pentru a adăuga un plus de rafinament.",
        },
      ],
    },
    {
      title: "Cafea și Ceai",
      images: [
        "https://i.imgur.com/IJnTDR2.jpeg",
        "https://i.imgur.com/HY4zndf.jpeg",
      ],
      text: [
        {
          subtitle: "Colțul de cafea perfect:",
          description:
            "a evenimentul dumneavoastră, oferim o selecție premium de cafea, de la espresso intens și cappuccino cremos, până la băuturi rafinate precum latte sau macchiato.",
        },
        {
          subtitle: "Selecție variată de ceaiuri:",
          description:
            "Pentru iubitorii de ceai, oferim o gamă largă de ceaiuri fine, de la ceaiuri verzi și negre, la infuzii din plante și fructe, toate provenind din cele mai bune surse.",
        },
        {
          subtitle: "Servire profesionistă:",
          description:
            "Un barista sau un specialist în ceaiuri va fi prezent pentru a prepara băuturile la standarde înalte, oferind sfaturi personalizate pentru alegerea celei mai bune băuturi, în funcție de preferințele invitaților și de atmosfera evenimentului.",
        },
        {
          subtitle: "Ambianță relaxantă:",
          description:
            "Colțul de cafea și ceai va fi amenajat într-un mod elegant și relaxant, completând perfect atmosfera evenimentului, și oferind invitaților o pauză de răsfăț.",
        },
      ],
    },
    {
      title: "Lumini artistice, sunet si Ecran LED",
      images: [
        "https://i.imgur.com/E5xamni.jpeg",
        "https://i.imgur.com/E5xamni.jpeg",
      ],
      text: [
        {
          subtitle: "Lumini Artistice de Impact:",
          description:
            "Transformați atmosfera evenimentului dumneavoastră cu ajutorul unui sistem de iluminat artistic avansat. Aceste lumini sunt perfect reglate pentru a adăuga un plus de energie, eleganță și atmosferă oricărui tip de eveniment, fie că este vorba de o nuntă, un concert sau o conferință.",
        },
        {
          subtitle: "Sistem Audio de Înaltă Calitate:",
          description:
            "Pentru un sunet impecabil, echipamentele audio de top sunt folosite pentru a asigura o acustică perfectă pe toată durata evenimentului. Indiferent de tipul de eveniment, sunetul clar și puternic va crea o experiență auditivă deosebită, amplificând impactul fiecărei prezentări, muzici sau momente speciale.",
        },
        {
          subtitle: "Ecran LED de Performanță:",
          description:
            "Ecranul oferă o claritate excelentă a imaginii, fiind perfect pentru prezentări, proiecte sau pentru a adăuga un element vizual spectaculos pe parcursul evenimentului. În plus, echipa noastră vă va asista cu instalarea și configurarea completă a materialelor video, asigurându-se că totul va decurge conform planului.",
        },
      ],
    },
    {
      title: "Fum Greu și Artificii Reci",
      images: [
        "https://i.imgur.com/xLkd6aI.jpeg",
        "https://i.imgur.com/xLkd6aI.jpeg",
      ],
      text: [
        {
          subtitle: "Fum Greu pentru Atmosferă Spectaculoasă:",
          description:
            "Fumurile grele sunt perfecte pentru a adăuga un efect dramatic și misterios oricărui moment special din cadrul evenimentului dumneavoastră. Aceste efecte de fum dens creează o atmosferă magică și impresionantă",
        },
        {
          subtitle: "Artificii Reci pentru Momente Memorabile:",
          description:
            "Artificiile reci sunt o alegere sigură și spectaculoasă pentru a adăuga un plus de magie evenimentului dumneavoastră. Spre deosebire de artificiile tradiționale, acestea nu emit căldură, fiind sigure pentru interior, dar oferind un spectacol luminos uimitor",
        },
        {
          subtitle: "Siguranță și Profesionalism:",
          description:
            " Atât efectele de fum greu, cât și artificiile reci sunt controlate și gestionate de echipe de profesioniști, asigurându-se că totul se desfășoară în deplină siguranță.",
        },
      ],
    },
    // Adaugă mai multe servicii aici
  ];
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Inițializează AOS cu durata animațiilor
  }, []);

  return (
    <div className="services-container-page">
      <h2 className="services-title-page">Serviciile Noastre</h2>
      <div className="services-list-page">
        {servicesData.map((service, index) => (
          <div data-aos="zoom-in-down" data-aos-once="true" className="service-item-page" key={index}>
            <div className="service-image-gallery-page">
              {service.images.map((image, idx) => (
                <img
                  key={idx}
                  src={image}
                  alt={`${service.title} ${idx + 1}`}
                  className="service-image-page"
                />
              ))}
            </div>
            <div className="service-info-page">
              <h1>{service.title}</h1>
              {service.text.map((item, idx) => (
                <div className="service-text-page" key={idx}>
                  <h3 className="service-subtitle-page">{item.subtitle}</h3>
                  <p className="service-description-page">{item.description}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServiceCard;
