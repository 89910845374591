import React from "react";
import PanoramicaHero from "../components/OurRooms/Panoramica/PanoramicaHero";
import PanoramicaDescription from "../components/OurRooms/Panoramica/PanoramicaDescription";
import PanoramicaImage from "../components/OurRooms/Panoramica/PanoramicaImage";

const PanoramicaPage = () => {
  const images = [
    "https://i.imgur.com/tamnfdz.jpeg",
    "https://i.imgur.com/ZZNuS2j.jpeg",
    "https://i.imgur.com/ZZNuS2j.jpeg",
    "https://i.imgur.com/ZZNuS2j.jpeg",
  ];
  return (
    <div>
      <PanoramicaHero />
      <PanoramicaDescription />
      <PanoramicaImage images={images} title="Gallery" />
    </div>
  );
};

export default PanoramicaPage;
