import React, { useEffect } from "react";
import "../../assets/Hero.css";
import AOS from "aos";
import "aos/dist/aos.css";

const Hero = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 }); // Inițializează AOS cu durata animațiilor
  }, []);
  return (
    <div data-aos="fade-up" className="hero">
      <div className="hero-content">
        <h1>Premium Park</h1>
        <p>
          Descoperă rafinamentul suprem la Premium Park - unde luxul și
          confortul se îmbină armonios pentru a crea o experiență de neuitat.
        </p>
        <button className="hero-button">Vezi Salile</button>{" "}
      </div>
    </div>
  );
};

export default Hero;
