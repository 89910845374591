import React from "react";
import "../../../assets/MicaImage.css";

const MicaImage = ({ images, title }) => {
  return (
    <div className="image-gallery-container">
      {title && <h2 className="gallery-title">{title}</h2>}
      <div className="gallery-grid">
        {images.map((image, index) => (
          <div key={index} className="gallery-item">
            <img
              src={image}
              alt={`GardenViewPoza ${index + 1}`}
              className="gallery-image"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MicaImage;
