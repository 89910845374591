import React from "react";
import Hero from "../components/Home/Hero";
import AboutUs from "../components/Home/AboutUs";
import OurServices from "../components/Home/OurServices";
import OurRooms from "../components/Home/OurRooms";


const HomePage = () => {

  return (
    <div >
      <Hero />
      <AboutUs /> <OurRooms /> <OurServices />{" "}
    </div>
  );
};

export default HomePage;
