import React from "react";
import EventGallery from "../components/Eveniments/EventGallery";

const EvenimentsPage = () => {
    const events = [
        {
            name: "Nuntă",
            type: "Nuntă",
            images: [
                "https://i.imgur.com/xLkd6aI.jpeg",
                "https://i.imgur.com/kMjEXhU.jpeg",
                "https://i.imgur.com/fjyULNU.jpeg",
                "https://i.imgur.com/7Kv6nSg.jpeg",
            ],
        },
        {
            name: "Cumetria",
            type: "Cumetrie",
            images: [
                "https://via.placeholder.com/300x200?text=Cumetrie+1",
                "https://via.placeholder.com/300x200?text=Cumetrie+2",
                "https://via.placeholder.com/300x200?text=Cumetrie+3",
                "https://via.placeholder.com/300x200?text=Cumetrie+4",
            ],
        },
        {
            name: "Ziua de naștere",
            type: "Zi de naștere",
            images: [
                "https://via.placeholder.com/300x200?text=Ziua+1",
                "https://via.placeholder.com/300x200?text=Ziua+2",
                "https://via.placeholder.com/300x200?text=Ziua+3",
                "https://via.placeholder.com/300x200?text=Ziua+4",
            ],
        },
    ];
    return (
        <div>
            <EventGallery events={events} />


        </div>
    );
};

export default EvenimentsPage;
