import React from "react";
import MicaHero from "../components/OurRooms/Mica/MicaHero";
import MicaDescription from "../components/OurRooms/Mica/MicaDescription";
import MicaImage from "../components/OurRooms/Mica/MicaImage";

const MicaPage = () => {
  const images = [
    "https://i.imgur.com/nGQ20WK.jpeg",
    "https://i.imgur.com/2QQdyya.jpeg",
    "https://i.imgur.com/UljDelR.jpeg",
    "https://i.imgur.com/UljDelR.jpeg",
  ];
  return (
    <div>
      <MicaHero />
      <MicaDescription />
      <MicaImage images={images} title="Gallery" />
    </div>
  );
};

export default MicaPage;
