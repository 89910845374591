import React from "react";
import "../../assets/ServiceHero.css";

const ServiceHero = () => {
  return (
    <div className="hero-service">
      <div className="hero-service-content">
        <h1>
          <span className="premium-park">Locația perfectă</span> pentru nunți,
          evenimente corporative și petreceri private
        </h1>
      </div>
    </div>
  );
};

export default ServiceHero;
