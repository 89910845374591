
import React from "react";
import "../../assets/ContactUs.css";

const Maps = () => {
    return (

        <div className="map-container">
            <iframe
                title="Google Map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2717.7504547862914!2d28.968154576806118!3d47.06474532523742!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c964b76f9bf023%3A0x68655310317bc14d!2sPremium%20Park!5e0!3m2!1sen!2s!4v1733866304870!5m2!1sen!2s"
                width="100%"
                height="400"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
            ></iframe>
        </div>


    );
};

export default Maps;
