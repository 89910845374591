import React from "react";
import GrandisHero from "../components/OurRooms/Grandis/GrandisHero";
import GrandisDescription from "../components/OurRooms/Grandis/GrandisDescription";
import GrandisImage from "../components/OurRooms/Grandis/GrandisImage";

const GrandisPage = () => {
  const images = [
    "https://i.imgur.com/MIVBizB.jpeg",
    "https://i.imgur.com/zateeIe.jpeg",
    "https://i.imgur.com/PEZ94B7.jpeg",
    "https://i.imgur.com/JjWLEif.jpeg",
  ];
  return (
    <div>
      <GrandisHero />
      <GrandisDescription />
      <GrandisImage images={images} title="Gallery" />
    </div>
  );
};

export default GrandisPage;
