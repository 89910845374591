import "./App.css";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navigation/Navbar";
import HomePage from "./pages/HomePage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import OurRoomsPage from "./pages/OurRoomsPage";
import ServicesPage from "./pages/ServicesPage";
import EvenimentsPage from "./pages/EvenimentsPage";
import ContactPage from "./pages/ContactPage";
import GrandisPage from "./pages/GrandisPage";
import ScrollToTop from "./pages/ScrollToTop";
import GardenViewPage from "./pages/GardenViewPage";
import PanoramicaPage from "./pages/PanoramicaPage";
import MicaPage from "./pages/MicaPage";
import SticlaPage from "./pages/SticlaPage";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/salile-noastre" element={<OurRoomsPage />} />
          <Route path="/servicii" element={<ServicesPage />} />
          <Route path="/evenimente" element={<EvenimentsPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/sala-grandis" element={<GrandisPage />} />
          <Route path="/sala-garden-view" element={<GardenViewPage />} />
          <Route path="/sala-panoramica" element={<PanoramicaPage />} />
          <Route path="/sala-mica" element={<MicaPage />} />
          <Route path="/sala-de-sticla" element={<SticlaPage />} />
          {/* Adaugă aici celelalte rute */}
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
