import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../assets/ContactUs.css";

const ContactUs = () => {
    const [formData, setFormData] = useState({
        name: "",
        phone: "",
        email: "",
        eventType: "",
        eventDate: "",
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const botToken = "8158427059:AAEjcFbeT_30C5U2LHKnjbcBNB50x0Ai2kU"; // Înlocuiește cu token-ul bot-ului
        const chatId = "-4778047293"; // Înlocuiește cu ID-ul chat-ului
        const telegramApiUrl = `https://api.telegram.org/bot${botToken}/sendMessage`;

        const message = `
📨 **Cerere nouă de contact**
👤 Nume: ${formData.name}
📞 Telefon: ${formData.phone}
📧 Email: ${formData.email}
🎉 Tip eveniment: ${formData.eventType}
📅 Data evenimentului: ${formData.eventDate}
        `;

        try {
            const response = await fetch(telegramApiUrl, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    chat_id: chatId,
                    text: message,
                    parse_mode: "Markdown",
                }),
            });

            if (response.ok) {
                setFormData({
                    name: "",
                    phone: "",
                    email: "",
                    eventType: "",
                    eventDate: "",
                });
                toast.success("Mesajul a fost trimis cu succes!");
            } else {
                toast.error("Eroare la trimiterea mesajului.");
            }
        } catch (error) {
            console.error("Eroare:", error);
            toast.error("A apărut o eroare la trimiterea mesajului.");
        }
    };
    return (
        <div className="contact-us-container">
            <div className="contact-us-content">
                <div className="contact-us-image">
                    <img
                        src="https://i.imgur.com/AVFdRpn.jpeg"
                        alt="Building View"
                    />
                </div>
                <div className="contact-us-form">
                    <h2>CONTACTE</h2>
                    <p>
                        Completați formularul de mai jos și noi vom reveni pentru a discuta
                        toate detaliile evenimentului dvs.
                    </p>
                    <form onSubmit={handleSubmit}>
                        <label>
                            Nume
                            <input
                                type="text"
                                name="name"
                                placeholder="Introduceți numele"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                        </label>
                        <label>
                            Număr de telefon *
                            <input
                                type="tel"
                                name="phone"
                                placeholder="Introduceți telefonul"
                                value={formData.phone}
                                onChange={handleChange}
                                required
                            />
                        </label>
                        <label>
                            Email *
                            <input
                                type="email"
                                name="email"
                                placeholder="Introduceți emailul"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </label>
                        <label>
                            Tipul evenimentului *
                            <select
                                name="eventType"
                                value={formData.eventType}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Selectați</option>
                                <option value="nunta">Nuntă</option>
                                <option value="aniversare">Aniversare</option>
                                <option value="cumetrie">Cumetrie</option>
                                <option value="altele">Altele</option>
                            </select>
                        </label>
                        <label>
                            Data evenimentului
                            <input
                                type="date"
                                name="eventDate"
                                value={formData.eventDate}
                                onChange={handleChange}
                                required
                            />
                        </label>
                        <button type="submit">TRIMITE</button>
                    </form>

                </div>

            </div>
            <ToastContainer />
        </div>
    );
};

export default ContactUs;
