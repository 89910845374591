import React from "react";
import "../../assets/Footer.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h3 className="footer-title">Despre Noi</h3>
          <p className="footer-description">
            Suntem o companie dedicată să aducem excelență și inovație în tot ce
            facem. Contactați-ne pentru mai multe detalii.
          </p>
          <div className="footer-social">
            <a href="https://www.facebook.com/premiumpark.md/" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebook} size="1x" />
            </a>
            <a href="https://www.instagram.com/premiumpark.md" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} size="1x" />
            </a>
            <a href="https://www.tiktok.com/@premium.park?_t=8nRxinQxVck&_r=1" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTiktok} size="1x" />
            </a>

          </div>
        </div>
        <div className="footer-section">
          <h3 className="footer-title">Linkuri utile</h3>
          <ul className="footer-links">
            <li>
              <a href="/">Acasă</a>
            </li>
            <li>
              <a href="/salile-noastre">Salile</a>
            </li>
            <li>
              <a href="/servicii">Servicii</a>
            </li>
            <li>
              <a href="/evenimente">Evenimente</a>
            </li>
            <li>
              <a href="/contact">Contact</a>
            </li>
          </ul>
        </div>

        <div className="footer-section">
          <h3 className="footer-title">Contact</h3>
          <p className="footer-description">Email: manager@premiumpark.md</p>
          <p className="footer-description">Telefon: +373 68 700 005</p>
        </div>

      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Premium Park. Toate drepturile rezervate.</p>
      </div>
    </footer>
  );
};

export default Footer;
