import React from "react";
import "../../../assets/SticlaHero.css";

const SticlaHero = () => {
  return (
    <div className="hero-service-sticla">
      <div className="hero-service-content-grandis">
        <h1>
          <span className="premium-park">
            Terasa de Sticla
            <br />
          </span>{" "}
          un spațiu elegant și rafinat, ideal pentru evenimente deosebite.
        </h1>
      </div>
    </div>
  );
};

export default SticlaHero;
