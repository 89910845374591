import React from "react";
import "../../assets/RoomsHero.css";

const RoomsHero = () => {
  return (
    <div className="hero-rooms">
      <div className="hero-rooms-content">
        <h1>
          <span className="premium-park">Transformă</span> evenimentul tău de
          vis în realitate la <span className="premium-park">Premium Park</span>
        </h1>
      </div>
    </div>
  );
};

export default RoomsHero;
