import React from "react";
import OurRooms from "../components/Home/OurRooms";
import RoomsHero from "../components/OurRooms/RoomsHero";

const OurRoomsPage = () => {
  return (
    <div>
      <RoomsHero />
      <OurRooms />
    </div>
  );
};

export default OurRoomsPage;
