import React from "react";
import "../../../assets/MicaHero.css";

const MicaHero = () => {
  return (
    <div className="hero-service-mica">
      <div className="hero-service-content-grandis">
        <h1>
          <span className="premium-park">
            Sala Mica
            <br />
          </span>{" "}
          un spațiu elegant și rafinat, ideal pentru evenimente deosebite.
        </h1>
      </div>
    </div>
  );
};

export default MicaHero;
