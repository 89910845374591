import React from "react";
import SticlaHero from "../components/OurRooms/Sticla/SticlaHero";
import SticlaDescription from "../components/OurRooms/Sticla/SticlaDescription";
import SticlaImage from "../components/OurRooms/Sticla/SticlaImage";

const SticlaPage = () => {
  const images = [
    "https://i.imgur.com/plDQuWe.jpeg",
    "https://i.imgur.com/cnKo2Za.jpeg",
    "https://i.imgur.com/qKtmjBm.jpeg",
    "https://i.imgur.com/plDQuWe.jpeg",
  ];
  return (
    <div>
      {" "}
      <SticlaHero />
      <SticlaDescription />
      <SticlaImage images={images} title="Gallery" />
    </div>
  );
};

export default SticlaPage;
