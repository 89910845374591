import React from "react";
import GardenViewHero from "../components/OurRooms/GardenView/GardenViewHero";
import GardenViewDescription from "../components/OurRooms/GardenView/GardenViewDescription";
import GardenViewImage from "../components/OurRooms/GardenView/GardenViewImage";

const GardenViewPage = () => {
  const images = [
    "https://i.imgur.com/9YETaYq.jpeg",
    "https://i.imgur.com/LQdMsJZ.jpeg",
    "https://i.imgur.com/SrrNmUF.jpeg",
    "https://i.imgur.com/761ddQO.jpeg",
  ];
  return (
    <div>
      <GardenViewHero />
      <GardenViewDescription />
      <GardenViewImage images={images} title="Gallery" />
    </div>
  );
};

export default GardenViewPage;
