import React from "react";
import ServiceCard from "../components/Services/ServiceCard";
import ServiceHero from "../components/Services/ServiceHero";

const ServicesPage = () => {
  return (
    <div>
      <ServiceHero />
      <ServiceCard />
    </div>
  );
};

export default ServicesPage;
